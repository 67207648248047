.confluence{
  img {
    transition:transform 0.25s ease;
    max-width: 100%;
    z-index: 1;
    cursor: zoom-in;
  }

  img:active {
    transform: scale(170%);
    cursor: zoom-out;
    position: relative;
    top: 50%;
    left: 1%;
    z-index: 2;
    box-shadow: 5px 5px 100px 100px #A6A6A6;
    backdrop-filter: blur(2px);
  }
  .confluence-information-macro-information{
    background-color: rgba(113, 187, 218, 0.4);
    padding: 1%;
    border-radius: 3px;
    margin-top: 1%;
  }
  .confluence-information-macro-warning{
    background-color: rgba(199, 51, 51, 0.4);
    padding: 1%;
    border-radius: 3px;
    margin-top: 1%;
  }
  .confluence-information-macro-note{
    background-color: rgba(234, 179, 16, 0.4);
    padding: 1%;
    border-radius: 3px;
    margin-top: 1%;
  }
  .confluence-information-macro-tip{
    background-color: rgba(78, 219, 31, 0.4);
    padding: 1%;
    border-radius: 3px;
    margin-top: 1%;
  }

  .expand-container {
    margin: 1%;
  }
  
  input[type='checkbox'] {
    display: none;
  }
  
  .lbl-toggle {
    display: block;
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    padding: 1rem;
    color: #1b1b1b;
    background: #F5F5F5;
  
    cursor: pointer;
  
    border-radius: 7px;
    transition: all 0.25s ease-out;
  }
  
  .lbl-toggle:hover {
    color: #676664;
  }
  
  .lbl-toggle::before {
    content: ' ';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);
    transition: transform .2s ease-out;
  }
  
  .toggle:checked + .lbl-toggle::before {
    transform: rotate(90deg) translateX(-3px);
  }
  
  .collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
  }
  
  .toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 100%;
  }
  
  .toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .collapsible-content .expand-content {
    background: rgba(109, 109, 106, 0.2);
    border: 1px solid rgba(0, 0, 0, .45);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: .5rem 1rem;
  }
  
}
